import React from 'react'
import { graphql } from 'gatsby';
import Default from 'gatsby-theme-carbon/src/templates/Default';
import {P, H2, Blockquote} from 'gatsby-theme-carbon/src/components/markdown';
import { Link } from 'carbon-components-react';
import CareerTile from './../components/career-tile';
import { ArrowRight16 } from '@carbon/icons-react';
import { trimSummary, getNarrative, slugify } from './../util'
import Tag from '../components/Tag'

const getSuccessStory = (career) => {
    let rel_story = null;
    if(career.relationships.field_career_success_story){
        let slug = slugify(career.relationships.field_career_success_story.title)
        rel_story = {
            quote: career.relationships.field_career_success_story.field_story_quote,
            path: `/stories/${slug}`,
        }
        if(career.relationships.field_career_success_story.field_story_quote_attribution){
            rel_story.attribution = career.relationships.field_career_success_story.field_story_quote_attribution
        }else{
            rel_story.attribution = career.relationships.field_career_success_story.title
        }
    }
    return rel_story;
}

const getRelatedCareers = (career) => {
	let career_related = []
	if(career.relationships && career.relationships.field_career_related){
		career_related = career.relationships.field_career_related.map((rel_career) => {
            let slug = slugify(rel_career.title)
            let lvl = {
                tid: rel_career.relationships.field_career_experience_level.drupal_internal__tid,
                name: rel_career.relationships.field_career_experience_level.name,
                color: rel_career.relationships.field_career_experience_level.field_experience_level_color,
            }
			let rel_data = {
                ...rel_career,
				nid: rel_career.drupal_internal__nid,
				url: `/browse-careers/${slug}`,
                career_description: trimSummary(rel_career.field_career_description.summary),
                career_experience_level: lvl,
                groups: lvl.name
			}
			return rel_data;
		})
	}
	return career_related
}

const Career = ({ data, location, pageContext }) => {

  const lvl = {
      name: data.career.relationships.field_career_experience_level.name,
      color: data.career.relationships.field_career_experience_level.field_experience_level_color
  };

	const career_data = {
        ...data.career,
        nid: data.career.drupal_internal__nid,
        career_category: data.career.relationships.field_career_category,
        career_profile_image: null,
        career_experience_level: lvl,
        career_success_story: getSuccessStory(data.career),
        career_description: getNarrative(data.career.field_career_description),
        career_related: getRelatedCareers(data.career)
    };
    let page_title = career_data.title
    pageContext = {...pageContext, frontmatter:
		{
		  title: page_title
		}
    };
    
	const renderRelatedCareers = () => {
		return career_data.career_related.map((rel_career) => 
			<div className="career-item">
					<CareerTile career={rel_career} isLast={true} />
			</div>
		)
	}

	return (
		<Default pageContext={pageContext} location={location}>

            <p>Job level: <Tag label={career_data.career_experience_level.name} color={career_data.career_experience_level.color} /></p>

            {career_data.career_success_story && 
            <Blockquote>
                <div>
                  <P>{career_data.career_success_story.quote}</P>
                  <cite><Link href={`${career_data.career_success_story.path}`}>{career_data.career_success_story.attribution}</Link></cite>
                </div>
            </Blockquote>
            }

            {
              career_data.career_description.map((desc) => 
                <div>
                    <H2>{desc.title}</H2>
                    <div className="richtext-body" dangerouslySetInnerHTML={{__html: desc.content}}></div>
                </div>
              )
            }

            {
                career_data.career_related.length > 0 && 
                <>
                    <H2>What else is possible?</H2>
                    <div className="resource-card-group careers-card-group careers-card-group-static">{renderRelatedCareers()}</div>
                </>
            }

            {
              career_data.career_category && 
              <div className="callout-link"><Link href={`/job-search?category=${slugify(career_data.career_category.name)}`} inline={false}><span>{`See related jobs that are currently open`}</span> <ArrowRight16 /></Link></div>
            }
            

        </Default>
    )
}

export default Career;

export const query = graphql`
  query($slug: String!) {
    career: nodeCareer(fields: { slug: { eq: $slug } }) {
		drupal_internal__nid
		field_career_description {
		  processed
		}
		title
		relationships {
		  field_career_experience_level {
			drupal_internal__tid
			name
			field_experience_level_color
		  }
		  field_career_success_story {
			drupal_internal__nid
			field_story_quote
			field_story_quote_attribution
			title
          }
          field_career_category {
            drupal_internal__tid
            name
          }
		  field_career_related {
			drupal_internal__nid
			title
			field_career_description {
			  summary
			}
			relationships {
			  field_career_experience_level {
				drupal_internal__tid
				name
				field_experience_level_color
			  }
			}
		  }
		}
    }
  }
`