import React from 'react'
import {ResourceCard} from 'gatsby-theme-carbon';
import Tag from './Tag'

export default function CareerTile({career, isLast}) {
	return (
		<ResourceCard
			subTitle={`${career.career_description}`}
			title={`${career.title}`}
			aspectRatio="1:1"
			actionIcon="arrowRight"
			href={career.url}
			className={isLast ? 'is-last-card' : ''}
		>
			<Tag label={career.career_experience_level.name} title="Experience level:" color={career.career_experience_level.color} />
		</ResourceCard>
	)
}
