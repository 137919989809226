import React from 'react'

function Tag({label, color = 'none', title = 'Tag:'}) {
    if(!label) return <></>
    return (
        <span className={`bx--tag bx--tag--${color}`} title={title}>
            <span>{label}</span>
        </span>
    )
}

export default Tag
